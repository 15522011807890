import { AppRouteProps } from "@mzara/component";
import React from "react";

const DetailContractPaymentContainer = React.lazy(() => import("./containers/DetailContractPaymentContainer"));
export const DetailContractPaymentRoute = [
    {
        path: "paying-info",
        element: <DetailContractPaymentContainer/>,
        roles: ["PAYING_SLIP"]

    },
] as Array<AppRouteProps>;
