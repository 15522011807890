import { AppRouteProps } from "@mzara/component";
import React from "react";


const DetailContractDetailedInformationContainer = React.lazy(() => import("./container/DetailContractDetailedInformationContainer"));
export const DetailContractDetailedInformationRoute = [
    {
        path: "detailed-info",
        element: <DetailContractDetailedInformationContainer/>,
        roles: ["PAYING_SLIP"]

    },
] as Array<AppRouteProps>;
